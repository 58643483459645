module.exports = [{
      plugin: require('../node_modules/@wardpeet/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":"@theme-ui/preset-dark"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W8BZJJD","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n          /* if the url contains \"builder.preview\" is being rendered inside of an iframe (Editor mode) */\n          const editorMode = typeof window !== 'undefined' && window.location.href.indexOf('builder.preview') > -1;\n          return {\n            disable_GTM: editorMode,\n            platform: 'gatsby',\n            isSpa: true\n          };\n        }"},"routeChangeEventName":"pageview","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/@mangoart/gatsby-plugin-dynatrace/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true,"tenantId":"rpm57080","applicationId":"APPLICATION-9AC3C69C8B5F8D95","apiToken":"dt0c01.FHESWFOBLIGXP3MKQJV2T4HR.GPV7NN4PZKDPF7CS7EHMVN7YIQD6SUFRC7UUH3SL2WFRMLV6CBKTCEPUSYUBVG4D","mode":1},
    }]
